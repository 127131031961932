<script lang="ts" setup>import { computed as _computed } from 'vue';
const checked = __MACROS_toRef(__props, "modelValue");

import { toRef as __MACROS_toRef } from "vue";
import { useFieldWithErrors } from '@consumer/composables/fieldWithErrors'
import type { Placement } from 'floating-vue'
import { uniqueId } from '@consumer/helpers/string'

withDefaults(defineProps<{
  label?: string
  modelValue?: boolean
  large?: boolean
  id?: string
  name: string
  disabled?: boolean
  error?: any
  errorTooltip?: boolean | Boolean | Placement
  align?: 'start' | 'center'

  // Provided automatically by GInput.
  hasErrors?: boolean
}>(), { disabled: false,align: 'center', })

const emit = defineEmits<{
  'update:modelValue': [value: any]
}>()

const slots = useSlots()

const hasLabel = computed(() => __props.label || slots)

const inputId = computed(() => __props.id ?? uniqueId('g-checkbox-'))
const inputElement = ref<HTMLInputElement>()

function onInput (event: Event) {
  const target = event?.target as HTMLInputElement
  emit('update:modelValue', target.checked)
}

const {
  activate,
  activated,
  showError,
} = useFieldWithErrors(inputElement, () => __props.error, () => __props.errorTooltip)
activate()
defineExpose({ inputElement, activate, activated })

const size = _computed(() => __props.large ? '24px' : '18px')
</script>

<template>
  <label :class="`relative flex items-${align} cursor-pointer ml-1`">
    <div
      class="rounded-v2xs flex flex-shrink-0 justify-center items-center
        focus-within:border-primary-dark box-border cursor-pointer p-1"
      :class="[
        align === 'start' ? 'mt-0.5': '',
        checked ? 'bg-primary-base border-0' : 'bg-white border-1 border-grey-500 border-opacity-70',
        hasErrors || showError ? '!border-red-base' : ''
      ]"
      :style="{ height: size, width: size }"
    >
      <GIcon v-if="checked" name="new-check" color="white" class="stroke-2" size="100%"/>
    </div>
    <span v-if="hasLabel" :for="inputId" class="ml-2 cursor-pointer select-none">
      <slot>
        {{ label }}
      </slot>
    </span>
    <input
      :id="inputId"
      ref="inputElement"
      :checked="checked"
      :name="name"
      type="checkbox"
      class="opacity-0 absolute"
      :disabled="disabled"
      :style="{ height: size, width: size }"
      @input.prevent="onInput"
    >
  </label>
</template>
